// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-el-js": () => import("./../../../src/pages/blog.el.js" /* webpackChunkName: "component---src-pages-blog-el-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-epikoinonia-el-js": () => import("./../../../src/pages/epikoinonia.el.js" /* webpackChunkName: "component---src-pages-epikoinonia-el-js" */),
  "component---src-pages-how-to-use-it-js": () => import("./../../../src/pages/how-to-use-it.js" /* webpackChunkName: "component---src-pages-how-to-use-it-js" */),
  "component---src-pages-index-el-js": () => import("./../../../src/pages/index.el.js" /* webpackChunkName: "component---src-pages-index-el-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-odigies-el-js": () => import("./../../../src/pages/odigies.el.js" /* webpackChunkName: "component---src-pages-odigies-el-js" */),
  "component---src-pages-our-technology-js": () => import("./../../../src/pages/our-technology.js" /* webpackChunkName: "component---src-pages-our-technology-js" */),
  "component---src-pages-politiki-aporritou-el-js": () => import("./../../../src/pages/politiki-aporritou.el.js" /* webpackChunkName: "component---src-pages-politiki-aporritou-el-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-technologia-el-js": () => import("./../../../src/pages/technologia.el.js" /* webpackChunkName: "component---src-pages-technologia-el-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */),
  "component---src-pages-ti-prosferoume-el-js": () => import("./../../../src/pages/ti-prosferoume.el.js" /* webpackChunkName: "component---src-pages-ti-prosferoume-el-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */),
  "component---src-templates-blog-entry-js": () => import("./../../../src/templates/blog-entry.js" /* webpackChunkName: "component---src-templates-blog-entry-js" */)
}

